<template>
    <div>
        <div id="printTest" style="width:794px;margin: auto;">
        <img src="../../../assets/Img/NIENYI-2.png" :alt="$t('All.年益集团')" style="width: 100px;position: absolute;margin-top: 0px;margin-left: 150px;">
        <!--<img src="../../../assets/Img/NIENYI-2.png" alt="年益集团" style="width: 100px;position: absolute;right: 32px;">-->
        <h2 style="text-align: center; line-height: 1;">{{this.$t('system.company')}}</h2>
        <h3 style="text-align: center;line-height: 1;">{{$t('All.实验报告')}}(Test report)</h3>
        <h4 style="text-align: center;line-height: 1;">{{$t('All.通知单号')}}：{{formData.Numbers}}</h4>
        <span style="width: 100px;position: absolute;margin-top: -30px;margin-left: 690px;">{{ formData.Gongdan }}</span>
        <table border="1"  style="width: 98%; table-layout: fixed;  margin: 0 auto;" class="table-layout" >
          <tr>
            <td style="width: 26%;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{$t('All.客户厂商')}}<br>Customer</p>
            </td>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;" colspan="2">
                <el-input v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Customer_manufacturer"></el-input>
                <span v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{form.Customer_manufacturer}}</span>
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.品名规格')}}<br>ITEM</p>
            </td>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;" colspan="2">
                <el-input v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Product_item"></el-input>
                <p style="margin: 0;line-height: 1.2;" v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{form.Product_item}}</p>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.试验项目')}}<br>Test Item</p>
            </td>
            <td class="tdd zhadminadd" colspan="2" style="text-align: center;">
                <el-input v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Project"></el-input>
                <p style="margin: 0;line-height: 1.2;" v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{form.Project}}</p>
            </td>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.试验日期')}}<br>Test date</p>
            </td>
            <td class="tdd procedure zhadminadd" colspan="2" style="text-align: center;">
                <el-input v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Date"></el-input>
                <span v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{form.Date}}</span>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{$t('All.实验室环境')}}<br>Test Environment</p>
            </td>
            <td style="text-align: center;" v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" class="tdd zhadminadd" colspan="5">
                {{$t('All.温度')}}：<el-input style="width: 30%;" v-model="form.Temperature"></el-input>°C，
                {{$t('All.湿度')}}：<el-input style="width: 30%;" v-model="form.Humidity"></el-input>%RH
            </td>
            <td style="text-align: center;" v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" class="tdd" colspan="5">
                {{$t('All.温度')}}/Temperature：{{form.Temperature}}°C，
                {{$t('All.湿度')}}/Humidity：{{form.Humidity}}%RH
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{$t('All.仪器设备')}}<br>Equipment</p>
            </td>
            <td v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" class="tdd" colspan="5">
              <div v-for="(item, index) in Sheb" :key="index">
                <el-input style="width: 230px;" v-model="item.Numbers" :placeholder="$t('All.请输入设备编号')">
                  <el-button slot="append" @click="Getinsft(item.Numbers,index)" icon="el-icon-search"></el-button>
                </el-input>
                <el-input style="width: 255px; float: right;" v-model="item.Name"></el-input>
                <el-button type="primary" @click="Shebadd" style="margin-left: 5px;" icon="el-icon-plus" circle></el-button>
                <el-button type="danger" :disabled="Sheb.length == 1" @click="Shebdel(item)"  style="margin-left: 5px;" icon="el-icon-delete" circle></el-button>
              </div>
            </td>
            <td style="text-align: left;" v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" class="tdd" colspan="5">
                    <p style="margin: 0;line-height: 1.2;">{{$t('All.编号')}}/Number：{{form.InstrumentNumbers}}<br>{{$t('All.名称')}}/Name：{{form.Instrument}}</p>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.试验条件')}}<br>Test condition</p>
            </td>
            <td class="tdd zhadminadd" colspan="5">
                <el-input class="session-file__form" :disabled="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Test_conditions" type="textarea" :placeholder="$t('All.请输入试验条件')"
                    :autosize="{minRows: 6, maxRows: 6}" :style="{width: '100%', overflow: 'hidden'}"></el-input>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;" colspan="6">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.测试状况及实验内容')}}<br>Test Status and Contents</p>
            </td>
          </tr>
          <tr valign="top">
           <td colspan="6" >
              <div>
                  <Toolbar class="toolbarclass" v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)"
                      style="border-bottom: 1px solid #ccc"
                      :editor="editor"
                      :defaultConfig="toolbarConfig"
                      :mode="mode"
                  />
                  <Editor
                  class="Editorclass"
                      style="height: 460px; overflow: hidden;"
                      disabled
                      v-model="form.Htmltext"
                      :defaultConfig="editorConfig"
                      :mode="mode"
                      @onCreated="onCreated"
                  />
              </div>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.最终判定')}}<br>Judgment</p>
            </td>
            <td  style="text-align: center;" class="tdd" colspan="5">
                <el-radio-group v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Determine" size="medium">
                    <el-radio  v-for="(item, index) in DetermineOptions" :key="index" :label="item.value">{{item.label}}</el-radio>
                </el-radio-group>
                <span v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{form.Determine}}</span>
            </td>
          </tr>
          <tr>
            <td class="tdd" style="text-align: center;">
              <p style="margin: 0;line-height: 1.2;">{{$t('All.备注')}}({{$t('All.异常描述')}})<br>Note</p>
            </td>
            <td class="tdd zhadminadd" colspan="5">
                <el-input class="session-file__form" :disabled="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" v-model="form.Opinion" type="textarea"
                    :autosize="{minRows: 3, maxRows: 3}" :style="{width: '100%'}"></el-input>
            </td>
          </tr>
          <tr></tr>
      </table>
      <table style="width: 90%;border-top: 0; margin: 0 auto;" class="table-layout">
        <tr>
            <td class="tdd" style="width: 16%;text-align: center;">
              {{$t('All.核准')}}/Approval:
            </td>
            <td class="tdd" style="width: 16%;text-align: center;">
                {{form.Approvername}}
            </td>
            <td class="tdd" style="width: 16%;text-align: center;">
              {{$t('All.审核')}}/Checked:
            </td>
            <td class="tdd" style="width: 16%;text-align: center;">
                {{form.Reviewername}}
            </td>
            <td class="tdd" style="width: 16%;text-align: center;">
              {{$t('All.制表')}}/Producer:
            </td>
            <td class="tdd" style="width: 16%;text-align: center;">
                {{form.Receivername}}
            </td>
          </tr>
          <tr>
            <td class="tdd"  style="text-align: right;" colspan="6">
                NYS1009-04-B
            </td>
          </tr>
      </table>
    </div>
        <div v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)" style="width: 90%;margin: auto;padding:10px;text-align: right;box-sizing: border-box;">
            <el-button @click="submitFormup" v-show="!istijiao" type="warning">{{$t('All.更新')}}</el-button>
            <el-button type="primary" v-show="istijiao" @click="submitForm">{{$t('All.提交')}}</el-button>
            <el-button type="danger" @click="Reportydel">{{$t('All.删除')}}</el-button>
      </div>
    </div>
  </template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  props: ['Numbers', 'FormData'],
  data () {
    return {
      formData: {},
      form: {
        Date: '',
        Numbers: '',
        Product_item: '',
        Customer_manufacturer: '',
        Project: '',
        Humidity: '',
        Temperature: '',
        InstrumentNumbers: '',
        Instrument: '',
        Test_conditions: '',
        Htmltext: '',
        Determine: '',
        Receiver: '',
        Receivername: '',
        Reviewer: '',
        Reviewername: '',
        Approver: '',
        Approvername: '',
        Opinion: ''
      },
      DetermineOptions: [{
        label: 'Pass',
        value: 'Pass'
      }, {
        label: 'Fail',
        value: 'Fail'
      }, {
        label: 'None',
        value: 'None'
      }],
      fileList: [],
      filedata: {
        Numbers: '',
        Type: 3
      },
      istijiao: true,
      isbool: false,
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '',
        readOnly: !(((this.FormData.State === 2 || this.FormData.State === 6) && this.$store.state.Login.User === this.FormData.Receiver)), // 只读、不可编辑
        scroll: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuupload/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            }
          },
          uploadVideo: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuuploadvideo/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            },
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 500 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 10
          }
        }
      },
      mode: 'default', // or 'simple'
      Sheb: [{
        Numbers: '',
        Name: ''
      }]
    }
  },
  computed: {},
  watch: {},
  created () {
    this.filedata.Numbers = this.Numbers
    this.editorConfig.MENU_CONF.uploadImage.meta.Numbers = this.Numbers
    this.editorConfig.MENU_CONF.uploadVideo.meta.Numbers = this.Numbers
    this.formData = this.FormData
    if (this.formData.State === 2 || this.formData.State === 6) {
      this.isbool = true
    }
    this.LaUserGetAllFiles(this.Numbers)
    this.Reporty(this.Numbers)
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  mounted () {},
  methods: {
    Shebadd () {
      this.Sheb.push({
        Numbers: '',
        Name: ''
      })
    },
    Shebdel (item) {
      if (this.Sheb.length === 1) {
        return this.$message.error(this.$t('All.最少保留一列'))
      }
      var index = this.Sheb.indexOf(item)
      if (index !== -1) {
        this.Sheb.splice(index, 1)
      }
    },
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      // 时
      const hour = new Date().getHours()
      // 分
      const minute = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      // 秒
      const second = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()

      const gettime = yy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute + ':' + second
      return gettime
    },
    async handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.splice(0, 0, data)
    },
    async handleRemove (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: 3,
          Numbers: this.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList.some((item, i) => {
          if (item.name === file.name) {
            this.fileList.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    xsimg (val) {
      var index = val.name.lastIndexOf('.')
      const obj = val.name.substring(index + 1, val.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          return val.url
        case 'BMP':
          return val.url
        case 'TIFF':
          return val.url
        case 'GIF':
          return val.url
        case 'PNG':
          return val.url
        case 'JPG':
          return val.url
        case 'PDF':
          return require('@/assets/Img/pdf.jpg')
        case 'XLS':
          return require('@/assets/Img/excle.jpg')
        case 'XLSX':
          return require('@/assets/Img/excle.jpg')
        case 'RAR':
          return require('@/assets/Img/rar.jpeg')
        case 'ZIP':
          return require('@/assets/Img/rar.jpeg')
        default:
          return require('@/assets/Img/nullimg.jpeg')
      }
    },
    async LaUserGetAllFiles (row) {
      try {
        const { data: res } = await this.$http.get('/api/Laboratory/LaUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) return
        for (let index = 0; index < res.response.length; index++) {
          if (res.response[index].Type === 3) {
            this.fileList.splice(0, 0, res.response[index])
          }
        }
      } catch (error) {
        alert(error)
      }
    },
    async Reporty (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Reporty?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response === null) {
          this.istijiao = true
          this.form.Numbers = this.FormData.Numbers
          this.form.Customer_manufacturer = this.FormData.Customer_manufacturer
          this.form.Product_item = this.FormData.Product_item
          this.form.Project = this.FormData.Experiment_name
          this.form.Test_conditions = this.FormData.Requirements
          this.form.Receiver = this.FormData.Receiver
          this.form.Receivername = this.FormData.Receivername
          this.form.Date = this.getCurrentTime()
        } else {
          const instsdnub = res.response.InstrumentNumbers.split(',')
          const instrument = res.response.Instrument.split(',')
          const datalist = []
          for (let index = 0; index < instsdnub.length; index++) {
            datalist.push({
              Numbers: instsdnub[index],
              Name: instrument[index]
            })
          }
          this.Sheb = datalist
          this.form = res.response
          this.istijiao = false
        }
      } catch (error) {
        alert(error)
      }
    },
    async submitForm () {
      this.form.InstrumentNumbers = ''
      this.form.Instrument = ''
      for (let index = 0; index < this.Sheb.length; index++) {
        if (index === this.Sheb.length - 1) {
          this.form.InstrumentNumbers += this.Sheb[index].Numbers
          this.form.Instrument += this.Sheb[index].Name
        } else {
          this.form.InstrumentNumbers += this.Sheb[index].Numbers + ','
          this.form.Instrument += this.Sheb[index].Name + ','
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Reportadd', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.Reporty(this.Numbers)
        this.$message.success(`${res.msg}！`)
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    async Reportydel () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Reportydel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.Reporty(this.Numbers)
        this.LaUserGetAllFiles(this.Numbers)
        this.$message.success(`${res.msg}！`)
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    async submitFormup () {
      this.form.Instrument = ''
      this.form.InstrumentNumbers = ''
      for (let index = 0; index < this.Sheb.length; index++) {
        if (index === this.Sheb.length - 1) {
          this.form.InstrumentNumbers += this.Sheb[index].Numbers
          this.form.Instrument += this.Sheb[index].Name
        } else {
          this.form.InstrumentNumbers += this.Sheb[index].Numbers + ','
          this.form.Instrument += this.Sheb[index].Name + ','
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Reportyup', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.$message.success(`${res.msg}！`)
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async Getinsft (row, index) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Getinsft?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response == null) {
          return this.$message.error(this.$t('All.系统暂无') + row + this.$t('All.的设备'))
        } else {
          if (res.response.Theredays > 1) {
            this.Sheb[index].Name = res.response.Name
            this.$message.success(`${res.response.Name}${this.$t('All.还有')}${res.response.Theredays}${this.$t('All.天到期')}！`)
          } else {
            this.$message.success(`${res.response.Name}${this.$t('All.已过期')}${res.response.Theredays}${this.$t('All.天，无法使用')}！`)
          }
        }
      } catch (error) {
        alert(error)
      }
    }
  }
}

</script>
<style lang="less" scoped>
@page {
  margin: 5px;
  }
  .el-upload__tip {
    line-height: 1.2;
  }

.editor-content-view p,
.editor-content-view li {
  white-space: pre-wrap; /* 保留空格 */
}

.editor-content-view blockquote {
  border-left: 8px solid #d0e5f2;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f1f1f1;
}

.editor-content-view code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}
.editor-content-view pre>code {
  display: block;
  padding: 10px;
}

.editor-content-view table {
  border-collapse: collapse;
}
.editor-content-view td,
.editor-content-view th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.editor-content-view th {
  background-color: #f1f1f1;
}

.editor-content-view ul,
.editor-content-view ol {
  padding-left: 20px;
}

.editor-content-view input[type="checkbox"] {
  margin-right: 5px;
}
  </style>
